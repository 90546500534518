.map-component{
    
    .maps{
        
        .leaflet-container {
           height: 500px;
            
           .leaflet-left {
            right: 50px !important;
            }
            .leaflet-control{
                float: right;
                top: 400px;
                right: -20px;
            }
            @media screen and (max-width:"768px"){
                height: 500px;
                .leaflet-control{
                    float: right;
                    top: 200px;
                    right: -20px;
                }
            }
        }
    }
}