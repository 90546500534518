* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@mixin containerStyle {
  width: 85%;
  margin: 0 auto;
}
@mixin flexStyle1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@mixin flexStyle2 {
  display: flex;
  align-items: center;
}
@mixin h2Style {
  margin-top: 0px;
  margin-bottom: 20px;
  line-height: 40px;
  font-family: "Poppins", sans-serif;
  font-size: clamp(24px, 4vw, 30px);
  font-weight: 700;
  text-transform: capitalize;
  color: #2a2a2a;
  
}
@mixin pStyle {
  text-align: center;
  font-size: clamp(12px, 4vw, 16px);
  color: #afafaf;
  font-family: "Poppins", sans-serif;
}
@mixin h3Style {
  font-family: "Poppins", sans-serif;
  font-size: clamp(14px, 4vw, 22px);
  font-weight: bold;
}
@mixin h4Style {
  font-family: "Poppins", sans-serif;
  font-size: clamp(12px, 4vw, 16px);
  font-weight: bold;
}
@mixin spanStyle {
  color: #afafaf;
  font-size: 14px;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
}

.header {
  .nav-container {
    nav {
      @include flexStyle1();
      width: 100%;
      padding: 1rem 2rem;
      background: rgba(34, 179, 193, 1);
      .navbar-logo {
        width: 25%;
        display: flex;
        justify-content: center;
        a {
          @include flexStyle2();
          text-decoration: none;
          color: #281e42;
          .logo-text {
            margin-left: 0.2rem;
            p {
              display: block;
              margin: 0;
              font-size: 30px;
              text-transform: lowercase;
              font-weight: bold;
              font-family: "Poppins", sans-serif;
              position: relative;
              top: 3px;
            }
            span {
              position: relative;
              bottom: 5px;
              margin: 0;
              display: block;
              font-family: "Poppins", sans-serif;
              font-weight: 400;
              font-size: 12px;
            }
          }
          img {
           
          }
        }
      }
      .navbar-items {
        width: 70%;
        .navbar-menu {
          @include flexStyle1();
          width: 100%;
          .nav-links {
            ul {
              @include flexStyle2();
              list-style: none;
              gap: 2rem;
              a {
                text-decoration: none;
                color: rgba(255, 255, 255, 1);
                font-size: clamp(14px, 4vw, 17px);
                font-weight: 400;
                cursor: pointer;
                position: relative;
                display: block;
                font-family: "Poppins", sans-serif;

                &::before {
                  content: " ";
                  width: 0%;
                  display: block;
                  height: 2px;
                  position: absolute;
                  top: -5px;
                  background: rgba(255, 255, 255, 1);
                  transition: 0.5s ease all;
                }
                &:hover {
                  &::before {
                    width: 45px;
                  }
                }
              }
            }
          }
          .nav-lang {
            .lang {
              @include flexStyle2();
              list-style: none;
              gap: 1rem;

              li {
                color: rgba(255, 255, 255, 1);
                font-size: 17px;
                font-family: "Poppins", sans-serif;
                font-weight: 500;
                cursor: pointer;
                transition: 0.5s ease-in-out;
                &:hover {
                  color: rgb(40, 30, 66);
                }
                a {
                  display: block;
                  color: rgba(255, 255, 255, 1);
                  font-size: 18px;
                  transition: 0.3s ease-in-out;
                  &:hover{
                    color: rgb(40, 30, 66);
                  }
                }
              }
            }
          }
        }
      }

      .menu-logo {
        display: none;
        .open-menu {
          cursor: pointer;
        }
        .close-menu {
          cursor: pointer;
        }
      }
    }
    .mobile-menus {
      display: none;
      width: 60%;
      .mobile {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        .nav-links {
          ul {
            list-style: none;
            li {
              padding: 0.8rem 0;
              border-bottom: 1px solid #eee;
              a {
                text-decoration: none;
                color: rgba(32, 16, 16, 0.753);
                font-size: clamp(14px, 4vw, 17px);
                font-weight: 400;
                cursor: pointer;
                position: relative;
                display: block;
                font-family: "Poppins", sans-serif;
                &:hover {
                  color: rgb(133, 111, 187);
                }
              }
            }
          }
        }
        .nav-lang {
          .lang {
            list-style: none;
            li {
              padding: 0.5rem 0;
              vertical-align: middle;
              margin: auto 0;
              border-bottom: 1px solid #eee;
              color: rgba(32, 16, 16, 0.753);
              text-transform: uppercase;
              font-size: clamp(14px, 4vw, 17px);
              font-family: "Poppins", sans-serif;
              font-weight: 400;
              cursor: pointer;
              transition: 0.5s ease-in-out;
              &:hover {
                color: rgb(40, 30, 66);
              }
              a {
                display: block;
                img {
                }
              }
            }
          }
        }
      }
    }
  }
}
.tour {
  padding: 3.5rem 0 0 0;
  .container {
    @include containerStyle();
    overflow: hidden;
    
    article {
      margin: 0 0 2rem 0;
      border-bottom: 1px solid #eee;
      padding-bottom: 2rem;
      h2 {
        @include h2Style();
        text-align: center;
      }
      p {
        @include pStyle();
        text-align: center;
        margin: 0 auto;
        width: 50%;
      }
      .carousel {
        margin: 1rem 0 1rem 0;
        .carousel-card {
          transition: 0.5s ease all;
          height: 400px;
          position: relative;
          margin: 2rem 0;
          gap: 1rem;

          .carousel-img {
            width: 290px;
            height: 240px;
            border-radius: 1.1rem;
            overflow: hidden;
            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }
          .carousel-text {
            position: absolute;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 15);
            top: 60%;
            transform: translateY(-50%);
            left: 15%;
            width: 280px;
            height: 350px;
            padding: 1.5rem 1rem;
            background: rgba(255, 255, 255, 1);
            border-radius: 1.1rem;
            padding-bottom: 1rem;
            .text-top {
              position: relative;
              @include flexStyle1();

              h3 {
                @include h3Style();
              }
              span {
                color: rgb(136, 120, 175);
                font-weight: bold;
                font-size: 18px;
              }
            }
            .text-detail {
              display: flex;
              justify-content: space-between;
              position: relative;
              &::after {
                content: "";
                width: 100%;
                height: 1px;
                margin: 0.8rem 0 0.3rem 0;
                position: absolute;
                left: 0;
                bottom: -10px;
                background: rgba(238, 238, 238, 1);
              }
              span {
                @include spanStyle();
              }
              .span {
                @include spanStyle();
                display: flex;
                align-items: center;
                img {
                  transform: translateY(-50%);
                  margin-right: 5px;
                }
              }
            }
            .text-bottom {
              margin-top: 1rem;
              h4 {
                @include h4Style();
              }
              ul {
                list-style: none;
                li {
                  margin: 0.2rem 0;
                  display: flex;
                  align-items: center;
                  span {
                    @include spanStyle();
                  }
                  img {
                    transform: translateY(-40%);
                    margin-right: 5px;
                  }
                }
              }
            }
            a {
              text-decoration: none;
              font-size: 18px;
              display: inline-block;
              text-align: center;
              margin: 0 auto;
              padding: 0.6rem 1.5rem;
              border-radius: 1.8rem;
              color: rgba(255, 255, 255, 1);
              background-color: #22b3c1;
              border: 1px solid #22b3c1;
              font-size: 15px;
              font-family: "Poppins", sans-serif;
              margin-top: 15px;
              transition: 0.5s ease all;
              overflow: hidden;
            }
          }
        }
      }
      .btn-group {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin: 1rem 0 0rem 0;

        .prev,
        .next {
          width: 2.8rem;
          height: 2.8rem;
          border-radius: 50%;
          border: 1px solid rgba(34, 179, 193, 1);
          cursor: pointer;
          display: grid;
          place-items: center;
          transition: 0.5s ease-in-out;
          .icon {
            color: rgba(34, 179, 193, 1);
            font-size: 1.4rem;
          }
          &:hover {
            background: rgba(34, 179, 193, 1);
            .icon {
              color: rgba(255, 255, 255, 1);
            }
          }
        }
      }
    }
  }
}
.main {
  .slides {
    width: 100%;
    position: relative;
    .slides-bg {
      overflow: hidden;
      .slide {
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 640px;
        background-position: center;
        text-align: center;
        .pi {
          font-size: clamp(14px, 4vw, 20px);
          font-family: "Poppins", sans-serif;
          color: rgba(255, 255, 255, 1);
          text-align: center;
          font-weight: 500;
          margin: 7rem 0 1rem 0;
        }
        h2 {
          font-size: clamp(18px, 4vw, 46px);
          font-family: "Poppins", sans-serif;
          color: rgba(255, 255, 255, 1);
          text-align: center;
          font-weight: bold;
          margin: 1rem 0 0.5rem 0;
        }
        a {
          display: inline-block;
          padding: 0.6rem 1.8rem;
          background: rgba(34, 179, 193, 1);
          text-decoration: none;
          border-radius: 1.8rem;
          font-family: "Poppins", sans-serif;
          color: rgba(255, 255, 255, 1);
          font-size: clamp(15px, 4vw, 17px);
          margin: 0.5rem 0;
        }
        .city-info {
          display: flex;
          justify-content: space-between;
          flex-wrap: nowrap;
          padding: 2rem 2.5rem;
          background: rgba(255, 255, 255, 1);
          border-radius: 4rem;
          width: 80%;
          margin: 3rem auto;
          .box {
            display: flex;
            width: 25%;
            align-items: center;
            gap: 1rem;
            text-align: left;
            .img {
              width: 3.5rem;
              height: 3.5rem;
              border-radius: 50%;
              border: 1px solid rgba(34, 179, 193, 1);
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0.5rem;
            }
            .text {
              p {
                font-family: "Poppins", sans-serif;
                font-size: clamp(12px, 4vw, 16px);
                color: rgba(175, 175, 175, 1);
              }
              h1 {
                font-family: "Poppins", sans-serif;
                font-size: clamp(14px, 4vw, 20px);
                color: rgba(34, 179, 193, 1);
              }
            }
          }
        }
      }
      .slide1 {
        background-image: url("..//..//public/Images/dubai.jpg");
      }
      .slide2 {
        background-image: url("..//..//public/Images/madrid.jpg");
      }
      .slide3 {
        background-image: url("..//..//public/Images/seoul.jpg");
      }
      .slide4 {
        background-image: url("..//..//public/Images/london.jpg");
      }
    }
    .lines {
      position: absolute;
      bottom: 50px;
      transform: translateX(50%);
      right: 50%;
      width: 80%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .line {
        text-align: center;
        width: 18%;
        position: relative;
        .div {
          position: absolute;
          top: 16px;
          left: 0;
          z-index: 1;
        }
        span {
          margin: 0;
          width: 100%;
          height: 2px;
          display: block;
          cursor: pointer;
          margin: 1rem 0;
          background: rgba(255, 255, 255, 1);
        }
        .div1 {
          height: 2px;
          background-color: red;
          animation: expandWidth 2s forwards infinite;
        }
        .div2 {
          animation-delay: 2s;
        }
        .div3 {
          animation-delay: 4s;
        }
        .div4 {
          animation-delay: 6s;
        }
      }
      strong {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}
.about {
  padding-top: 2rem;
  @media screen and (max-width:992px){
        padding-top: 1rem;
      }
  .container {
    @include containerStyle();
    .destenation {
      width: 85%;
      @media screen and (max-width: 840px) {
        width: 100%;
      }
      h2 {
        @include h2Style();
        margin-bottom: 0.5rem;
        width: 36%;
        line-height: 40px;
        @media screen and (max-width: "1140px") {
          width: 100%;
          line-height: 30px;
        }
      }
      p {
        @include pStyle();
        text-align: left;
        margin-bottom: 1rem;
        width: 40%;
        line-height: 30px;
        font-size: clamp(14px, 4vw, 15px);
        @media screen and (max-width: "840px") {
          width: 100%;
          margin-bottom: 0.5rem;
        }
      }
      .cards {
        .card {
          width: 100%;
          @include flexStyle2();
          align-items: start;
          gap: 1.8rem;
          padding: 2rem 0;
          border-bottom: 1px solid #eee;
          padding-bottom: 2rem;
          @media screen and (max-width:"768px") {
            padding: 1rem 0;
          }
          .card-text {
            width: 65%;
            .text {
              display: flex;
              align-items: start;
              justify-content: space-between;

              .text-item {
                h3 {
                  @include h3Style();
                  font-family: "Poppins", sans-serif;
                  font-size: clamp(20px, 4vw, 22px);
                }
                p {
                  width: auto;
                }
              }
              a {
                text-decoration: none;
                background: rgba(34, 179, 193, 1);
                padding: 0.6rem 1.5rem;
                border-radius: 1.8rem;
                font-family: "Poppins", sans-serif;
                color: rgba(255, 255, 255, 1);
                font-size: clamp(14px, 4vw, 16px);
                margin: 0;
                @media screen and (max-width: "1040px") {
                  display: none;
                }
              }
            }
            p {
              width: auto;
            }
            .hide-link {
              margin: 1rem 0;
              display: none;

              a {
                text-decoration: none;
                background: rgba(34, 179, 193, 1);
                padding: 0.6rem 1.5rem;
                border-radius: 1.8rem;
                font-family: "Poppins", sans-serif;
                color: rgba(255, 255, 255, 1);
                font-size: clamp(14px, 4vw, 16px);
                margin: 0;
              }
              @media screen and (max-width: "1040px") {
                display: block;
                a {
                  padding: 0.4rem 1rem;
                }
              }
            }
          }
          .card-image {
            width: 35%;

            height: 200px;
            border-radius: 1.2rem;
            overflow: hidden;
            img {
              width: 100%;

              object-fit: cover;
              height: 100%;
            }
          }
          @media screen and (max-width: "840px") {
            flex-direction: column;
            margin: 1rem 0;
            gap: 1rem;
            .card-text {
              width: 100%;
              .text {
                a {
                  padding: 0.3rem;
                  width: 140px;
                  text-align: center;
                  position: relative;
                  left: 0;
                  z-index: 100;
                }
              }
            }
            .card-image {
              width: 100%;
              max-width: 840px;
            }
          }
        }
      }
    }
    .countries {
      padding-top: 2rem;
      @media screen and (max-width:992px){
        padding-top: 1rem;
      }
      h2 {
        @include h2Style();
        width: 40%;
        line-height: 40px;
        @media screen and (max-width: "1140px") {
          line-height: 30px;
          width: 100%;
        }
      }
      .pi {
        @include pStyle();
        text-align: left;
        font-size: clamp(12px, 4vw, 15px);
        line-height: 30px;
        width: 40%;
        margin-bottom: 1rem;
        @media screen and (max-width: "1140px") {
          width: 100%;
        }
      }
      .boxes {
        width: 85%;
        @media screen and (max-width: 840px) {
          width: 100%;
        }
        .box {
          display: flex;
          gap: 1.8rem;
          padding: 2rem 0 1.5rem 0;
          border-bottom: 1px solid #eee;
          @media screen and (max-width:"768px") {
            padding-top: 0;
            margin-bottom: 2rem;
            gap: 1rem;
          }

          .box-image {
            width: 35%;
            height: 200px;
            border-radius: 1.2rem;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .box-text {
            width: 65%;
            .text {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .text-item {
                h3 {
                  font-family: "Poppins", sans-serif;
                  font-size: clamp(20px, 4vw, 22px);
                  line-height: 30px;
                  margin: 0;
                }
                p {
                  @include pStyle();
                  margin: 0;
                  text-align: left;
                }
              }

              a {
                display: block;
                text-decoration: none;
                background: rgba(34, 179, 193, 1);
                padding: 0.6rem 1.5rem;
                border-radius: 1.8rem;
                font-family: "Poppins", sans-serif;
                color: rgba(255, 255, 255, 1);
                font-size: clamp(14px, 4vw, 16px);
                margin: 0;
                @media screen and (max-width: "500px") {
                  display: none;
                }
              }
            }
            .pip {
              @include pStyle();
              font-size: clamp(12px, 4vw, 15px);
              text-align: left;
              line-height: 30px;
              margin-top: 1rem;
              @media screen and (max-width: "768px") {
                margin-top: 0;
              }
            }
            .box-lines {
              display: flex;
              align-items: center;
              border-top: 1px solid #eee;
              border-bottom: 1px solid #eee;
              margin: 1rem 0;
              padding: 0.5rem 0;
              justify-content: space-between;
              .box-line {
                display: flex;
                align-items: center;
                gap: 0.8rem;
                p{
                @include pStyle();
                }
                @media screen and (max-width: "500px") {
                  flex-wrap: wrap;
                  justify-content: center;
                  align-items: start;
                  gap: .6rem;
                  text-align: center;

                  width: 20%;
                  p{
                 
                  margin: 0;
                  line-height: 20px;
                  
                  }
                }
                .img {
                  img {
                    transform: translateY(50%);
                  }
                }
              }
            }
            .lines-link {
              a {
                color: rgb(136, 120, 175);
                text-decoration: none;
                font-size: 15px;
                font-family: "Poppins", sans-serif;
                transition: 0.4s ease all;
                &:hover {
                  .icon-link {
                    margin-left: 5px;
                  }
                }
                // .icon-link{
                //   margin-left: 10px;
                // }
              }
            }
          }
          @media screen and (max-width: "840px") {
            flex-direction: column;
            .box-text {
              width: 100%;
              margin: 0 auto;
              .text {
                a {
                  padding: 0.3rem;
                  width: 140px;
                  text-align: center;
                  position: relative;
                  left: 0;
                  z-index: 100;
                }
              }
            }
            .box-image {
              width: 100%;
            }
          }
        }
      }
    }
    .best-location {
      padding-top: 2rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @media screen and (max-width:"768px") {
            padding: 0;
            
          }
      h2 {
        @include h2Style();
        width: 60%;
        text-align: center;
      }
      p {
        @include pStyle();
        width: 60%;
        margin-bottom: 20px;
      }
      .gallery {
        display: flex;
        width: 70%;
        gap: 1rem;
        
        .image-container {
          overflow: hidden;
          border-radius: 1.8rem;
          flex: 1 1 calc(20% - 10px);
          cursor: pointer;
          transition: flex 0.3s ease;
          height: 400px;
          position: relative;
          // .div{
          //   position: absolute;
          //   bottom: 200px;
          //   right: 50%;
          //   text-align: center;
          //   p{
          //     @include pStyle();
          //     color: rgba(255, 255, 255, 1);
          //   }
          // }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: left;
          }
        }
        .expanded {
          flex: 1 1 100%;
        }
        @media screen and (max-width:"768px") {
           width: 100%;
           gap: .5rem;
          .image-container{
            height: 300px;
            &:nth-child(even){
              display: none;
            }
          }
          
        }
      }
    }
  }
}
.contact {
  position: relative;
  .contact-info {
    width: 100%;
    background-image: url("..//..//public/Images/bgImage.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 550px;
    margin: 2rem 0 0rem 0;
    
    .container {
      @include containerStyle();

      .pi {
        @include pStyle();
        color: rgba(255, 255, 255, 1);
        font-size: clamp(18px, 4vw, 20px);
        margin: 12rem 0 1rem 0;
        display: inline-block;
      }
      span {
        width: 100px;
        height: 2px;
        background: rgba(255, 255, 255, 1);
        text-align: center;
        margin: 1rem auto;
        display: block;
      }
      h2 {
        @include h2Style();
        font-size: clamp(22px, 4vw, 45px);
        color: rgba(255, 255, 255, 1);
        margin: 1.5rem 0;
      }
      text-align: center;
      a {
        display: inline-block;
        padding: 0.6rem 1.5rem;
        font-family: "Poppins", sans-serif;
        font-size: clamp(14px, 4vw, 15px);
        text-decoration: none;
        background: rgba(255, 255, 255, 1);
        border-radius: 1.8rem;
        color: rgb(136, 120, 175);
        font-weight: 500;
        margin: 1rem 0;
        transition: 0.5s ease-in-out;
        &:hover {
          color: rgba(255, 255, 255, 1);
          background: rgb(136, 120, 175);
        }
      }
    }
    @media screen and (max-width: "768px") {
      height: 400px;
      .container{
        h2{
          margin: 1rem 0;
        }
        .pi{
          margin: 4rem 0 1rem 0;
          
        }
        span{
          margin: .5rem auto;
        }
      }
    }
  }
  .wrapper{
    position: relative;
    top: -100px;
    z-index: 100;
    @include containerStyle();
    z-index: 10;
    .cards {
      
      padding: 1rem 0;
      z-index: 100;
      display: flex;
      align-items: start;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      text-align: center;
      gap: 2rem;
      .card {
        width: 30%;
        padding: 2rem 1rem;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
        border-radius: 1.8rem;
        .img {
          width: 60px;
          height: 60px;
          margin: 0 auto;
          background: rgba(240, 240, 240, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }
        h3 {
          @include h3Style();
          margin: 0.5rem 0;
          font-size: clamp(16px, 4vw, 20px);
        }
        p {
          @include pStyle();
          margin: 0.5rem 0;
        }
      }
      @media screen and (max-width: "1050px") {
        flex-wrap: wrap;
        .card {
          width: 45%;
          max-width: 600px;
        }
      }
      @media screen and (max-width: "768px") {
        flex-wrap: wrap;
        .card {
          width: 100%;
          margin: 0 auto;
          max-width: 500px;
        }
      }
    }
  }
  .map-form {
    margin-top: -60px;
    @media screen and (max-width:"768px"){
      margin-top: -80px;
    }
    .container {
      @include containerStyle();
      
      .for-map {
        background: rgba(249, 249, 249, 1);
        
        padding-bottom: 2 rem;
        margin: 0rem 0 4rem 0;
        border-radius: 1.8rem;
        overflow: hidden;
        .form {
          margin: 2rem auto;
          width: 80%;
          @media screen and (max-width:"768px"){
            width: 90%;
            margin: 0;
          }

          h2 {
            @include h2Style();
            text-align: center;
            font-size: clamp(20px, 4vw, 28px);
            line-height: 30px;
           
            span {
              color: rgba(34, 179, 193, 1);
            }
          }
          .form-container {
            margin: 2rem auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            .input-group {
              display: flex;
              justify-content: space-between;
              margin-bottom: 15px;
              @media (max-width: 600px) {
                margin-bottom: 0;
              }
              .input-item {
                display: flex;
                flex-direction: column;
                width: 48%;
                @media screen and (max-width: "600px") {
                  width: 100%;
                  margin: 0.4rem 0;
                }
                input,
                select {
                  border: 1px solid #ccc;
                  border-radius: 4px;
                  margin-top: 5px;
                  border-radius: 1.8rem;
                  padding: 0rem 1rem;
                  height: 3rem;
                  font-size: 16px;
                  cursor: pointer;
                  outline: none;
                  &:hover {
                    outline: 1px solid rgb(136, 120, 175);
                  }
                  @media (max-width: 600px) {
                    height: 2.6rem;
                    width: 100%;
                  }
                }
                input[type="date"] {
                  @include pStyle();
                  text-align: left;
                }
                select {
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                  background: url("..//..//public/Images/drop-down.png");
                  background-repeat: no-repeat;
                  background-position: right;
                  background-size: 16px;
                  background-position-x: 95%;
                  padding-left: 30px;
                  padding-right: 30px;
                  color: #afafaf;
                  font-family: "Poppins", sans-serif;
                  text-align: left;
                   @media screen and (max-width:"768px"){
                  background: none;
            
          }
                  option {
                    padding: 10px;
                    color: #333;
                    background-color: #fff;
                    font-size: 16px;
                    font-family: "Poppins", sans-serif;
                  }
                }
                input::placeholder {
                  @include pStyle();
                  text-align: left;
                }
                label {
                  @include pStyle();
                  text-align: left;
                  margin-bottom: 5px;
                  font-size: 16px;
                }
              }
              @media (max-width: 600px) {
                flex-direction: column;
              }
            }
            .single-item {
              display: block;
              width: 100%;
              .input-item {
                width: 100%;
              }
            }
            button {
              padding: 10px 15px;
              background-color: rgba(34, 179, 193, 1);
              color: white;
              border: none;
              border-radius: 4px;
              cursor: pointer;
              transition: background-color 0.3s;
              border-radius: 1.8rem;
              height: 3rem;
              font-size: 16px;
              font-family: "Poppins", sans-serif;
              margin-top: 15px;
              @media (max-width: 600px) {
                height: 2.6rem;
                width: 100%;
              }
            }
          }
          
          .modal-button {
            background-color: rgb(136, 120, 175);
            color: white;
            border: none;
            padding: .6rem 2rem;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
            transform: translateX(-50%);
            position: relative;
            left: 50%;
            bottom: 50%;
            cursor: pointer;
            border-radius: 1.8rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .modal-p{
            @include pStyle();
            margin: 3rem 0 1rem 0;
            padding: 1rem 2rem;
          }
        }
        .map-x {
         
          width: 100%;
          height: 500px;
          overflow: hidden;
          margin: 0rem 0 3rem 0;
          
          
        }
        @media screen and (max-width:"768px"){
           background: transparent;
            .map-x{
            height: 300px;
            margin: 0rem 0 1rem 0;
          }
      }
    }
  }
}}
footer {
  
  background-image: url("..//..//public/Images/footer-img.jpeg");
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: center;
  .pi{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
    background: rgba(34, 179, 193, 1);
    border-top: 2px solid rgba(255, 255, 255, 1);
    p{
      @include pStyle();
      width: 80%;
      color: rgba(255, 255, 255, 1);
    }
  }
  .footer-container {
    width: 85%;
    margin: 0 auto;
    padding: 1rem 0 0rem 0;
   @media screen and (max-width:"768px"){
    padding: .5rem 0 0 0;
   }
    .content {
      width: 100%;
      padding: 2rem auto;
      @media screen and (max-width:"568px") {
        padding: 0rem 0 0 0;
        margin: 0rem auto 2rem auto;
      }
      display: flex;
      margin: 2rem auto;
      align-items: center;
      justify-content: space-between;
      .left-content {
        width: 60%;
        h2 {
          @include h2Style();
          color: rgba(255, 255, 255, 1);
          font-size: clamp(20px, 4vw, 36px);
          margin: 10px 0;
        }
        p {
          @include pStyle();
          text-align: left;
          font-size: clamp(18px, 4vw, 20px);
          margin: 10px 0;

          color: rgba(255, 255, 255, 1);
        }
      }
      .right-content {
        width: 30%;
        text-align: center;
        a {
          text-decoration: none;
          color: rgba(255, 255, 255, 1);
          padding: 0.6rem 1.5rem;
          background: rgba(169, 189, 202, 0.9);
          border-radius: 1.8rem;
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          border: 1px solid rgba(255, 255, 255, 1);
        }
      }
      @media screen and (max-width: "992px") {
        flex-wrap: wrap;
        gap: 1rem;
        align-items: start;
        .left-content {
          width: 100%;
          max-width: 500px;
        }
        .right-content {
          width: 100%;
          text-align: left;
        }
      }
    }
    
  }
}
@media screen and (max-width: "1200px") {
  .tour {
    .container {
      article {
        .carousel {
          .carousel-card {
          }
        }
      }
    }
  }
}
@media screen and (max-width: "992px") {
  .header {
    .nav-container {
      position: relative;
      nav {
        padding: 1.5rem 0.5rem;
        .navbar-logo {
          width: auto;
        }
        .navbar-items {
          display: none;
          .navbar-menu {
            flex-direction: column;
            row-gap: 0.7rem;
            .nav-links {
              ul {
                flex-direction: column;
                row-gap: 0.7rem;
              }
            }
            .nav-lang {
              .lang {
                flex-direction: column;
                row-gap: 0.7rem;
              }
            }
          }
        }

        .menu-logo {
          display: block;
        }
      }
      .mobile-menus {
        display: block;
        position: absolute;
        background: rgba(255, 255, 255, 1);
        z-index: 100;
        width: 80%;
        top: 100px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .main {
    .slides {
      .slides-bg {
        .slide {
          .city-info {
            flex-wrap: wrap;
            gap: 1rem;
            .box {
              width: 45%;
            }
          }
        }
      }
    }
  }
  .tour {
    .container {
      article {
        .carousel {
          margin: 1rem 0;
          .carousel-card {
            width: 100%;
            .carousel-img {
              width: 270px;
            }
            .carousel-text {
              width: 240px;
              position: absolute;
              left: 50px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: "768px") {
  .header {
    .nav-container {
      .mobile-menus {
        width: 100%;
      }
    }
  }
  .tour {
    padding-top: 2rem;
    .container {
      article {
      margin: 0 0 1rem 0;
      border-bottom: 1px solid #eee;
      padding-bottom: 2rem;
        h2 {
          margin-bottom: 10px;
          line-height: 30px;
        }
        p {
          width: 100%;
          margin-bottom: 10px;
        }
        .carousel {
          margin: 0;

          .carousel-card {
            margin: 1rem 0 2rem 0;
            .carousel-img {
              width: 270px;
            }
            .carousel-text {
              width: 260px;
              left: 13%;
            }
          }
        }
        .btn-group {
          
        }
      }
    }
  }
  .main {
    .slides {
      .slides-bg {
        .slide {
          height: 500px;
          .pi {
            font-size: clamp(14px, 4vw, 20px);

            text-align: center;
            font-weight: 500;
            margin: 3rem 0 1rem 0;
          }
          h2 {
            font-size: clamp(24px, 4vw, 46px);
            font-family: "Poppins", sans-serif;
            color: rgba(255, 255, 255, 1);
            text-align: center;
            font-weight: bold;
            margin: 1rem 0 0.5rem 0;
          }
          .city-info {
            border-radius: 1rem;
            width: 90%;
            max-width: 800px;
            padding: 1.2rem;
            .box {
              gap: 0.5rem;
              .img {
                width: 3rem;
                height: 3rem;
                border-radius: 50%;
                border: 1px solid rgba(34, 179, 193, 1);
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.5rem;
              }
              .text {
                p {
                  font-family: "Poppins", sans-serif;
                  font-size: clamp(12px, 4vw, 13px);
                  color: rgba(175, 175, 175, 1);
                }
                h1 {
                  font-family: "Poppins", sans-serif;
                  font-size: clamp(12px, 4vw, 15px);
                  color: rgba(34, 179, 193, 1);
                }
              }
              a {
                padding: 0.3rem 1rem;
                background: rgba(34, 179, 193, 1);
                text-decoration: none;
                border-radius: 1.4rem;
                font-family: "Poppins", sans-serif;
                color: rgba(255, 255, 255, 1);
                font-size: clamp(12px, 4vw, 15px);
                margin: 0.5rem 0;
                text-align: center;
              }
            }
          }
        }
      }
      .lines {
        bottom: 20px;
      }
    }
  }
  .about {
    .container {
      .best-location {
        h2 {
          width: 100%;
          margin-bottom: 0;
        }
        p {
          width: 100%;
        }
      }
    }
  }
  .contact {
    .map-form {
      .container {
        .for-map {
          padding-bottom: 0rem;
        margin: 0rem 0 1rem 0;
          .form {
            margin: 1rem auto;
            h2 {
              margin-bottom: 1rem;
            }
            .form-container {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
